import React from "react";
import Subpage from "./Subpage";
import { Tab } from "@headlessui/react";

import Side from "./assets/enlarge_side.png";
import VillaBonn from "./assets/villabonn.png";
import Contact from "./assets/contact.jpg";
import Dresscode from "./assets/dress.jpeg";

export default function App() {
  return (
    <div className="md:container md:mx-auto md:border-x-2 flex flex-col w-screen min-h-screen font-playfair">
      <header className="py-4 flex items-center border-b border-rose-950 bg-white justify-center gap-1 flex-wrap text-center sticky top-0 z-50 text-sm">
        Schon ausgefüllt? Hier geht's zu den{" "}
        <a
          href="https://joroshochzeit.de"
          className="text-rose-500 hover:text-rose-600 cursor-pointer"
        >
          Infos
        </a>
      </header>
      <main className="h-screen relative overflow-scroll scrollbar-thin">
        <Subpage id="home" image={Side} alt="not found" isEven={false}>
          <form
            action="https://api.joroshochzeit.de/create_guest/"
            method="POST"
            className="flex flex-col w-full overflow-scroll text-sm px-4"
          >
            <label for="email" className="font-semibold mt-2 mb-1">
              Email:
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="rounded p-1 border"
              required
            />
            <label for="name" className="font-semibold mt-2 mb-1">
              Vorname:
            </label>
            <input
              type="text"
              id="name"
              name="name"
              className="rounded p-1 border"
              required
            />
            <label for="surname" className="font-semibold mt-2 mb-1">
              Nachname:
            </label>
            <input
              type="text"
              id="surname"
              name="surname"
              className="rounded p-1 border"
              required
            />
            <div className="mt-6 mb-3">
              <div className="flex flex-row gap-2 py-1">
                <input
                  type="radio"
                  id="attendance_true"
                  name="attendance"
                  value="true"
                  checked
                />
                <label for="attendance_true">
                  Ich nehme an der Hochzeit teil
                </label>
              </div>
              <div className="flex flex-row gap-2 py-1">
                <input
                  type="radio"
                  id="attendance_false"
                  name="attendance"
                  value="false"
                />
                <label for="attendance_false">
                  Ich nehme <b>nicht</b> an der Hochzeit teil
                </label>
              </div>
            </div>
            <div className="mt-6 mb-3">
              <div className="flex flex-row gap-2 py-1">
                <input
                  type="radio"
                  id="attendance_friday_true"
                  name="attendance_friday"
                  value="true"
                />
                Ich nehme am Freitagabend teil am get-together
              </div>
              <div className="flex flex-row gap-2 py-1">
                <input
                  type="radio"
                  id="attendance_friday_false"
                  name="attendance_friday"
                  value="false"
                />
                Ich nehme am Freitagabend nicht am get-together teil
              </div>
            </div>
            <div className="mt-6 mb-3">
              <div className="flex flex-row gap-2 py-1">
                <input
                  type="radio"
                  id="attendance_breakfast_true"
                  name="attendance_breakfast"
                  value="true"
                />
                Ich nehme Sonntags am Frühstück teil
              </div>
              <div className="flex flex-row gap-2 py-1">
                <input
                  type="radio"
                  id="attendance_breakfast_false"
                  name="attendance_breakfast"
                  value="false"
                />
                Ich nehme nicht Sonntags am Frühstück teil
              </div>
            </div>
            <label for="pref" className="font-semibold self-center mt-2 mb-1">
              Ernährung:
            </label>
            <input
              type="radio"
              id="no_requirements"
              name="pref"
              value="0"
              checked
            />
            Keine Einschränkungen
            <input type="radio" id="vegetarian" name="pref" value="1" />
            Vegetarisch
            <input type="radio" id="vegan" name="pref" value="2" />
            Vegan
            <label
              for="allergies"
              className="font-semibold self-center mt-2 mb-1"
            >
              Allergien:
            </label>
            <input
              type="text"
              id="allergies"
              name="allergies"
              className="rounded p-1 border"
            />
            <label for="notes" className="font-semibold self-center mt-2 mb-1">
              Notizen{" "}
              <span className="text-gray-600 ">
                (Bitte tragt hier die Namen eurer Kinder ein, wenn sie noch
                keine E-Mail haben)
              </span>
              :
            </label>
            <textarea
              id="notes"
              name="notes"
              rows="4"
              cols="50"
              className="rounded p-1 min-h-30 border resize-none"
            ></textarea>
            <input
              type="submit"
              value="Absenden"
              className="rounded bg-rose-500 hover:bg-rose-600 text-white p-2 mt-4"
            />
          </form>
        </Subpage>
      </main>
    </div>
  );
}
